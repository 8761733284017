import NetWork from '@xiaoe/js-tools';

export const microCouponType = new Map([
    [0, 'MICRO_COUPON_STATISTICS'],
    [1, 'MICRO_COUPON_RECEIVE'],
    [2, 'MICRO_COUPON_USE']
]);

export const customerType = new Map([
    [0, 'MICRO_VISITLIST'],
    [1, 'MICRO_BUSINESS']
]);

// 企微头像，由于直接返回企微头像，canvas造成跨域，故另起一个接口，由后端转好返回
const DISTRIBUTEAVATAR = 'forward_admin/wework_distribute/xe.customer.get.staff.avatar/1.0.0';
// 活动详情
const ACTIVITYDETAIL = 'forward_admin/wework_distribute/xe.c.activity.detail/1.0.0';
// 邀请列表
const CUSTOMERINVITELIST = 'forward_admin/wework_distribute/xe.c.distribute.customer.list/1.0.0';
// 推广链接
const DISTRIBUTEURL = 'forward_admin/wework_distribute/xe.c.join.activity/1.0.0';

// 全员推广-分享链路
const CLUENET = 'forward_admin/wework_distribute/xe.c.share_link_tree.list/1.0.0';

// 访客和商机列表接口
const CUSTOMER_LIST_MAP = new Map([
    [customerType.get(0), 'forward_admin/wework_distribute/xe.c.activity_visitor.list.get/1.0.0'],
    [customerType.get(1), 'forward_admin/wework_distribute/xe.c.activity_visitor.merchant.list/1.0.0']
]);
//全民推广-访客列表哈希表
const VISITLISTMAP = new Map([
    //正常访客列表
    ['NORMALVISITLIST', 'forward_admin/wework_distribute/xe.c.activity_visitor.list.get/1.0.0'],
    //优惠券领券列表
    ['COUPONVISITLIST', 'forward_admin/wework_distribute/xe.c.activity.coupon.user_list/1.0.0']
]);
//优惠券哈希表
const COUPONINFOMAP = new Map([
    //优惠券 概览数据
    ['COUPONOVERVIEW', 'forward_admin/wework_distribute/xe.c.distribute.coupon.staff.effect/1.0.0'],
    //优惠券 基本信息
    ['COUPONBASEINFO', 'forward_admin/wework_distribute/xe.c.distribute.coupon.staff.detail/1.0.0']
]);

export const fetchCouponView = (params, whichInterface) => {
    return NetWork.ajax({
        url: COUPONINFOMAP.get(whichInterface),
        methods: 'post',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || `${COUPONINFOMAP.get(whichInterface)}接口错误`;
        });
};

//活动详情-商机-访客列表
// const BUSINESS = 'forward_admin/wework_distribute/xe.c.activity_visitor.merchant.list/1.0.0';

const currying = (controller) => {
    return (url, params, methods = 'post') => {
        url = `${controller}/${url}`;
        return NetWork.ajax({ url, methods, params });
    };
};
const http = currying('forward_admin/wework_distribute');
// 活动 - 微页面优惠券
const MICRO_COUPON_MAP = new Map([
    [microCouponType.get(0), 'forward_admin/wework_distribute/xe.c.activity_coupons.get/1.0.0'],
    [microCouponType.get(1), 'forward_admin/wework_distribute/xe.c.activity_coupon_receive.list.get/1.0.0'],
    [microCouponType.get(2), 'forward_admin/wework_distribute/xe.c.activity_coupon_use.list.get/1.0.0']
]);

// 微页面
export const fetchMicroCoupon = (params, interfaceType) => {
    return NetWork.ajax({
        url: MICRO_COUPON_MAP.get(interfaceType),
        methods: 'post',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '获取失败';
        });
};

// 访客和商机列表
export const fetchActivityList = (params, interfaceType) => {
    return NetWork.ajax({
        url: CUSTOMER_LIST_MAP.get(interfaceType),
        methods: 'post',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '获取失败';
        });
};

export const fetchPromotionCenter = (params) => {
    return NetWork.ajax({
        url: '/forward_admin/wework_distribute/xe.c.distribute.center/1.0.0',
        methods: 'post',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '获取失败';
        });
};

// 活动列表页
export const fetchMoreActivityList = (params) => {
    return NetWork.ajax({
        url: '/forward_admin/wework_distribute/xe.c.activity.list/1.0.0',
        methods: 'post',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '获取失败';
        });
};
export const fetchCouponList = (params, whichInterface) => {
    console.log(params);
    return NetWork.ajax({
        url: VISITLISTMAP.get(whichInterface),
        methods: 'post',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '获取失败';
        });
};

// 商机对应的客户列表
export const fetchBusinessCustomerList = (params) => {
    return NetWork.ajax({
        url: '/forward_admin/wework_distribute/xe.c.visitor.merchant.list/1.0.0',
        methods: 'post',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '获取失败';
        });
};

// 获取商机锁客的总数
export const getBusinessCustomerTotal = (params) => {
    return http('xe.c.distribute.rank.data/1.0.0', params);
};

// 客户列表
export const fetchCustomerList = (params) => {
    return NetWork.ajax({
        url: '/forward_admin/wework_distribute/xe.c.visitor.list.get/1.0.0',
        methods: 'post',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '获取失败';
        });
};
// 客户详情
export const fetchCustomerDetail = (params) => {
    return NetWork.ajax({
        url: '/forward_admin/wework_distribute/xe.c.customer.detail/1.0.0',
        methods: 'post',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '获取失败';
        });
};
// 客户详情-关系变更记录
export const fetchCustomerRelationChange = (params) => {
    return NetWork.ajax({
        url: '/forward_admin/wework_distribute/xe.c.customer.change.log/1.0.0',
        methods: 'post',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '获取失败';
        });
};
// 客户详情-活动参与记录
export const fetchCustomerActivityParticipate = (params) => {
    return NetWork.ajax({
        url: '/forward_admin/wework_distribute/xe.c.customer.act.list/1.0.0',
        methods: 'post',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '获取失败';
        });
};
// 活动详情
export const fetchActivityDetail = (params) => {
    return NetWork.ajax({
        url: ACTIVITYDETAIL,
        methods: 'post',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '获取失败';
        });
};

// 获取企微头像
export const fetchDistributeAvatar = (params) => {
    return NetWork.ajax({
        url: DISTRIBUTEAVATAR,
        methods: 'post',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '获取失败';
        });
};

export const fetchClueNet = (params) => {
    console.log(params);
    return NetWork.ajax({
        url: CLUENET,
        methods: 'post',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '获取失败';
        });
};
export const fetchActivityVisitorList = (params) => {
    console.log('tag', params);
    // return NetWork.ajax({
    //     url: CLUENET,
    //     methods: 'post',
    //     params
    // })
    //     .then((data) => {
    //         return MOCKCLUE;
    //     })
    //     .catch((error) => {
    //         throw error.msg || '获取失败';
    //     });
};
// 邀请客户列表
export const fetchCustomerInviteList = (params) => {
    return NetWork.ajax({
        url: CUSTOMERINVITELIST,
        methods: 'post',
        params
    })
        .then((data) => {
            // return MOCKINVITEUSERLIST;
            return data;
        })
        .catch((error) => {
            throw error.msg || '获取分享链路失败';
        });
};

// 推广链接
export const fetchDistributeUrl = (params) => {
    return NetWork.ajax({
        url: DISTRIBUTEURL,
        methods: 'post',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '获取失败';
        });
};

// 获取用户信息
// export const getUserRoleInfo = () => {
//     return NetWork.ajax({
//         url: '/forward_admin/wework/wework_manage/get_user_role',
//         methods: 'post',
//         params: {}
//     })
//         .then((data) => {
//             return data;
//         })
//         .catch((error) => {
//             throw error.msg || '获取失败';
//         });
// };

// 获取CRM打通所需参数
export const getStaffId = (params) => {
    return NetWork.ajax({
        url: '/forward_admin/wework_distribute/xe.customer.get.staff.id/1.0.0',
        methods: 'post',
        params
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '获取失败';
        });
};
