<template>
    <div class="invite-dialog">
        <van-popup
            :value="value"
            position="bottom"
            round
            safe-area-inset-bottom
            :overlay-style="{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }"
            @click-overlay="$emit('input')"
        >
            <div class="invite-popup">
                <div class="popupOrnament"></div>
                <div class="popupContent">
                    <div class="popupInviteContent">
                        <div class="promoteSpeechSkills">
                            <div class="title">
                                <div class="titleLeft">推广话术</div>
                                <div
                                    class="titleRight"
                                    v-show="promoteTextHeightCompareFlag"
                                    @click="isShowContent = !isShowContent"
                                >
                                    <span>{{ isShowContent ? '展开' : '收起' }}</span>
                                    <img src="@/assets/images/promote_top.png" alt="" v-if="!isShowContent" />
                                    <img src="@/assets/images/promote_bottom.png" alt="" v-else />
                                </div>
                            </div>
                            <div class="promoteSpeech">
                                <template v-if="share_content">
                                    <p :class="isShowContent ? 'openText' : 'text'" ref="text">
                                        <i v-html="share_content" ref="compareText"></i>
                                    </p>
                                    <!-- <p class="compareText" ref="compareText" v-html="share_content"></p> -->
                                </template>
                                <template v-else>
                                    <div class="noShareContent">暂无话术</div>
                                </template>
                            </div>
                            <ul class="operationTabs">
                                <li
                                    v-for="(item, index) in inviteListAgain"
                                    :key="index"
                                    :data-clipboard-text="item.content"
                                    @click="clickPromote(item)"
                                    :class="item.btn"
                                >
                                    <img :src="item.img" alt="" />
                                    <p>{{ item.text }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="popupInviteContent">
                        <div class="shareContent">
                            <ul class="operationTabs">
                                <li v-for="(item, index) in shareList" :key="index" @click="clickPromote(item)">
                                    <img :src="item.img" alt="" />
                                    <p>{{ item.text }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="popupInviteContent">
                        <div class="promoteSettings" @click="goSeting">
                            推广引流设置
                            <img class="arror-icon" src="@/assets/images/arror_right_2.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="invite-popup">
                <div class="invite-popup-header">
                    <div class="header-title">{{ showExample ? '示例' : '推广' }}</div>
                    <div class="header-close">
                        <img class="close-icon" src="@/assets/images/close_black.png" @click="handleClose" alt="" />
                    </div>
                </div> -->
            <!-- <div :class="['invite-popup-content', { 'invite-popup-content__example': showExample }]"> -->
            <!-- 推广设置 -->
            <!-- <div v-if="!showExample" class="content-seting"> -->
            <!-- 设置 -->
            <!-- <div class="seting" v-if="isInList"> -->
            <!-- <div class="header">
                                <div class="left">引流设置</div>
                                <div class="right" @click="goSeting">
                                    去设置
                                    <img class="arror-icon" src="@/assets/images/arror_right_2.png" alt="" />
                                </div>
                            </div>
                            <div class="info">
                                请选择在直播间展示的联系方式
                                <span class="example" @click="showExample = true">示例</span>
                            </div>
                            <div class="content">
                                <div class="set-list">
                                    <div
                                        v-for="(item, index) in setList"
                                        :key="index"
                                        :class="[
                                            'set-item',
                                            { 'set-item-select': item.isSelect, 'set-item-empty': !item.value }
                                        ]"
                                        @click="clickSetItem(item)"
                                    >
                                        {{ item.text }}
                                    </div>
                                </div>
                            </div>
                        </div> -->
            <!-- 分享 -->
            <!-- <div class="share">
                            <div class="header">
                                <div class="left">推广方式</div>
                            </div>
                            <div class="info">用户访问你的海报或链接，即可锁客</div>
                            <div class="content">
                                <div class="invite-list">
                                    <div
                                        v-for="(item, index) in inviteList"
                                        :key="index"
                                        :data-clipboard-text="item.content"
                                        :class="['invite-item', item.btn ? item.btn : '']"
                                        @click="clickPromote(item)"
                                    >
                                        <div v-if="index === 0" class="openLink-icon">
                                            <img :src="item.img" class="share-icon" alt="" />
                                            <div class="tips">推荐</div>
                                        </div>
                                        <img v-else :src="item.img" class="share-icon" alt="" />
                                        <span class="share-text">{{ item.text }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
            <!-- 推广示例 -->
            <!-- <div v-if="showExample" class="content-example">
                        <div class="example-text">
                            <p>1、直播间屏蔽店铺二维码</p>
                            <p>2、优先展示推广员的引流信息</p>
                        </div>
                        <div class="example-image">
                            <img class="image" src="@/assets/images/exmaple_image.png" alt="" />
                        </div>
                    </div> -->
            <!-- <img v-else :src="item.img" class="share-icon" alt="" />
                    <span class="share-text">{{ item.text }}</span> -->
            <!-- </div>
                <span
                    id="copy-btn-dom"
                    ref="copy-dom"
                    @click="copyShareContent"
                    :data-clipboard-text="share_content"
                ></span> -->
            <!-- <div class="invite-popup-footer">
        <div class="footer-cancel" @click="$emit('input')">取消</div>
        </div> -->
            <!-- </div> -->
        </van-popup>
        <share-poster
            :visible.sync="showPoster"
            :posterData="posterData"
            :posterImg="posterImg"
            @posterCompleted="posterCompleted"
            :isPromotion="true"
        />
    </div>
</template>

<script>
import { getCookie } from '@/utils/cookies';
import { debounce } from '@/utils/tools';
import Clipboard from 'clipboard';
import SharePoster from '@xiaoe/venus/dist/xiaoe/ui/h5/share-poster/share-poster.js';
import '@xiaoe/venus/dist/xiaoe/ui/h5/share-poster/share-poster.css';
export default {
    name: 'InviteDialog',
    components: { SharePoster },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        // 当前用户是否在灰度名单
        isInList: {
            type: Boolean,
            default: false
        },
        // 分享链接
        distributeUrl: {
            type: String,
            default: ''
        },
        // 海报信息
        posterData: {
            type: Object,
            default: () => {}
        },
        // 活动话术
        share_content: {
            type: String,
            default: ''
        },
        activityType: {
            type: String,
            default: 'activity'
        },
        showCopyTextLink: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showPoster: false,
            showExample: false,
            posterImg: '',
            isShowContent: true,
            promoteTextHeightCompareFlag: false,
            setList: [
                {
                    id: 1,
                    text: '头像',
                    field: 'pic_url',
                    value: '',
                    isSelect: false,
                    callback: debounce((item) => {
                        this.saveSeting(item);
                    })
                },
                {
                    id: 2,
                    text: '职位',
                    field: 'title',
                    value: '',
                    isSelect: false,
                    callback: debounce((item) => {
                        this.saveSeting(item);
                    })
                },
                {
                    id: 3,
                    text: '手机号',
                    field: 'phone',
                    value: '',
                    isSelect: false,
                    callback: debounce((item) => {
                        this.saveSeting(item);
                    })
                },
                {
                    id: 4,
                    text: '微信',
                    field: 'qy_wechat',
                    value: '',
                    isSelect: false,
                    callback: debounce((item) => {
                        this.saveSeting(item);
                    })
                }
            ]
        };
    },
    computed: {
        inviteList() {
            const list = [
                {
                    img: require('@/assets/images/link_open.svg'),
                    text: '打开链接',
                    name: 'openLink',
                    content: ''
                }
            ];
            if (this.share_content) {
                list.push({
                    img: require('@/assets/images/text_and_link.svg'),
                    text: '复制话术及链接',
                    name: 'copyLink',
                    content: this.share_content + '\n' + this.distributeUrl,
                    btn: 'copyLinkAndTextBtn'
                });
            }
            list.push({
                img: require('@/assets/images/link_icon.png'),
                text: '复制链接',
                name: 'copyLink',
                content: this.distributeUrl,
                btn: 'copyLinkBtn'
            });
            list.push({
                img: require('@/assets/images/poster_icon.png'),
                text: '海报分享',
                name: 'posterShare',
                content: ''
            });

            return list;
        },
        inviteListAgain() {
            const list = [];
            if (this.share_content) {
                list.push({
                    img: require('@/assets/images/copyMessage.png'),
                    text: '复制话术',
                    name: 'copyLink',
                    content: this.share_content,
                    btn: 'copyText'
                });
            }
            list.push({
                img: require('@/assets/images/copyLink.png'),
                text: '复制链接',
                name: 'copyLink',
                content: this.distributeUrl,
                btn: 'copyLink'
            });
            if (this.share_content) {
                list.push({
                    img: require('@/assets/images/copyMessageAndLink.png'),
                    text: '复制话术及链接',
                    name: 'copyLink',
                    content: this.share_content + '\n' + this.distributeUrl,
                    btn: 'copyLinkAndText'
                });
            }
            return list;
        },
        shareList() {
            const list = [
                {
                    img: require('@/assets/images/wechat.png'),
                    text: '分享到微信',
                    name: 'openLink',
                    content: ''
                },
                {
                    img: require('@/assets/images/poster.png'),
                    text: '海报分享',
                    name: 'posterShare',
                    content: ''
                }
            ];
            return list;
        }
    },
    watch: {
        value: {
            handler(newVal) {
                if (newVal) {
                    this.showExample = false;
                    this.$nextTick(() => {
                        this.promoteTextHeightCompare();
                    });
                } else {
                    this.isShowContent = true;
                }
            },
            immediate: true
        },
        showPoster: {
            handler(newVal) {
                if (!newVal) {
                    this.posterImg = '';
                }
            },
            deep: true
        }
    },
    methods: {
        promoteTextHeightCompare() {
            if (!this.share_content) {
                this.promoteTextHeightCompareFlag = false;
                return;
            }
            let flag = false;
            let textHeight = this.$refs['text'].offsetHeight;
            let compareHeight = this.$refs['compareText'].offsetHeight;
            if (textHeight < compareHeight) {
                flag = true;
            } else {
                flag = false;
            }
            this.promoteTextHeightCompareFlag = flag;
            // return flag;
        },
        // 海报生成完成
        posterCompleted(img) {
            this.posterImg = img;
        },
        // 获取推广设置信息
        getDistributeSeting() {
            return new Promise((resolve, reject) => {
                const params = {
                    app_id: getCookie('with_app_id'),
                    user_id: localStorage.getItem('userId')
                };
                this.$ajax('get_distribute_seting', params)
                    .then((res) => {
                        if (res.data.code === 0) {
                            res.data.data.qy_wechat =
                                String(res.data.data.qy_wechat_is_current) === '0'
                                    ? res.data.data.qy_wechat_qr_code
                                    : res.data.data.qw_code;
                            this.setList.forEach((item) => {
                                item.value = res.data.data[item.field];
                                item.isSelect = res.data.data.select_options.includes(item.field);
                            });
                            resolve(res.data);
                        } else {
                            this.$toast('网络错误');
                            this.$emit('input');
                            reject();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$toast('网络错误');
                        this.$emit('input');
                        reject();
                    });
            });
        },

        // 点击信息设置项
        clickSetItem(item) {
            if (!item.value) {
                this.$dialog
                    .confirm({
                        message: '请前往 【推广引流设置】<br/>设置个人信息',
                        width: '6rem',
                        confirmButtonText: '去设置',
                        confirmButtonColor: '#1472ff',
                        overlayStyle: { backgroundColor: 'rgba(0, 0, 0, 0.4)' },
                        closeOnClickOverlay: true,
                        className: 'message-tips-dialog',
                        getContainer: () => document.querySelector('.invite-dialog')
                    })
                    .then(() => {
                        this.goSeting();
                    })
                    .catch(() => {
                        console.log('cancel');
                    });
                return;
            }
            item.callback(item);
        },

        // 保存推广设置
        saveSeting(item) {
            const params = {
                app_id: getCookie('with_app_id'),
                user_id: localStorage.getItem('userId'),
                select_option: item.field
            };
            item.isSelect = !item.isSelect;
            this.$ajax('set_distribute_type', params)
                .then((res) => {
                    if (res.data.code === 0) {
                        console.log('设置成功', res.data);
                    } else {
                        this.$toast('设置失败');
                    }
                })
                .catch(() => {
                    this.$toast('设置失败');
                });
        },

        handleClose() {
            if (this.showExample) {
                this.showExample = false;
            } else {
                this.$emit('input');
            }
        },

        // 跳转推广信息设置页
        goSeting() {
            this.$router.push('/staff_promotion/distributeSeting?activity_detail=true');
        },

        // 打开链接
        async openLink() {
            // await this.$refs['copy-dom'].click();
            setTimeout(() => {
                const aDom = document.createElement('a');
                aDom.href = this.distributeUrl;
                aDom.click();
            }, 10);
        },
        clickPromote(item) {
            if (item.name === 'posterShare') {
                this.generatePoster();
                this.$emit('input');
            } else if (item.name === 'openLink') {
                this.openLink();
                this.$emit('input');
            } else {
                this.copyLink(item);
            }
        },
        // 生成分享海报
        generatePoster() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            this.showPoster = true;
        },
        // 复制活动话术
        // copyShareContent(event) {
        //     event.preventDefault();
        //     const clipboard = new Clipboard('#copy-btn-dom');
        //     console.log(clipboard);
        //     clipboard.on('success', (e) => {
        //         console.log(e);
        //         // 因为可能会有多个复制, 所以复制成功后需要清除页面中的回调函数
        //         clipboard.e && (clipboard.e.success = []);
        //     });
        //     clipboard.on('error', (e) => {
        //         console.error(e);
        //     });
        //     return true;
        // },
        // 复制-链接或话术及链接
        copyLink(item) {
            if (!item.btn) {
                return;
            }
            const clipboard = new Clipboard(`.${item.btn}`);
            clipboard.on('success', (e) => {
                console.log(e);
                this.$toast(
                    `${
                        item.btn === 'copyText'
                            ? '成功复制话术'
                            : item.btn === 'copyLink'
                            ? '成功复制链接'
                            : '成功复制话术及链接'
                    }`
                );
                // 因为可能会有多个复制, 所以复制成功后需要清除页面中的回调函数
                clipboard.e && (clipboard.e.success = []);
            });
            clipboard.on('error', (e) => {
                console.error(e);
                this.$toast('复制失败');
            });
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .message-tips-dialog {
    border-radius: 0.16rem;
    .van-dialog__message {
        padding: 0.52rem 0.48rem;
        font-size: 0.28rem;
        line-height: 0.4rem;
    }
    .van-button {
        height: 0.96rem !important;
        font-size: 0.32rem !important;
    }
    .van-dialog__cancel {
        color: #333;
    }
    .van-dialog__confirm {
        font-weight: 500;
    }
}

.invite-dialog {
    color: #333;
    background-color: #f5f5f5;
    .invite-popup {
        // display: flex;
        // flex-direction: column;
        background: #f5f5f5;
        padding-top: 1px;
        max-height: 80vh;
        overflow: auto;
    }
    .invite-popup-header {
        position: relative;
        height: 0.96rem;
        line-height: 0.96rem;
        text-align: center;
        .header-title {
            font-size: 0.32rem;
            font-weight: 500;
        }
        .header-close {
            position: absolute;
            top: 0.04rem;
            right: 0.32rem;
            width: 0.24rem;
            height: 0.24rem;
            .close-icon {
                width: 100%;
                height: 100%;
            }
        }
    }
    .invite-popup-content {
        min-height: 6.48rem;
        padding: 0.48rem 0.32rem;
        box-sizing: border-box;
        &.invite-popup-content__example {
            padding: 0.32rem 0.64rem;
        }
        .content-seting {
            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 0.48rem;
                .left {
                    font-size: 0.32rem;
                    font-weight: 500;
                }
            }
            .info {
                height: 0.32rem;
                line-height: 0.32rem;
                margin-top: 0.08rem;
                font-size: 0.24rem;
                color: #666;
            }
            .content {
                margin-top: 0.32rem;
            }
            .seting {
                .right {
                    display: flex;
                    align-items: center;
                    font-size: 0.24rem;
                    color: #999;
                    cursor: pointer;
                    .arror-icon {
                        width: 0.32rem;
                        height: 0.32rem;
                        margin-left: 0.08rem;
                    }
                }
                .example {
                    margin-left: 0.16rem;
                    color: #1472ff;
                    cursor: pointer;
                }
                .set-list {
                    display: flex;
                }
                .set-item {
                    position: relative;
                    line-height: 0.4rem;
                    padding: 0.14rem 0.22rem;
                    border: 0.02rem solid #ccc;
                    text-align: center;
                    border-radius: 0.08rem;
                    margin-right: 0.24rem;
                    &-select {
                        border-color: #1472ff;
                    }
                    &-select:after {
                        content: '';
                        position: absolute;
                        top: -0.02rem;
                        left: -0.02rem;
                        width: 0.48rem;
                        height: 0.48rem;
                        height: 0.48rem;
                        background-image: url('../../../assets/images/select_type_icon.png');
                        background-size: 100%;
                        background-repeat: no-repeat;
                    }
                    &-empty {
                        color: #999;
                    }
                    &-empty:after {
                        content: '未设置';
                        position: absolute;
                        top: 0;
                        left: -0.02rem;
                        width: 0.76rem;
                        height: 0.32rem;
                        line-height: 0.32rem;
                        transform: translateY(-50%);
                        text-align: center;
                        font-size: 0.2rem;
                        border-radius: 0.08rem 0.08rem 0.08rem 0;
                        background-color: #bfbfbf;
                        color: #fff;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            .share {
                margin-top: 0.64rem;
                .invite-list {
                    display: flex;
                }
                .invite-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0.08rem 0.28rem 0.24rem;
                    margin-right: 0.16rem;
                    cursor: pointer;
                    .openLink-icon {
                        width: 0.96rem;
                        height: 0.96rem;
                        line-height: 0.96rem;
                        text-align: center;
                        background-image: url('../../../assets/images/square_share.png');
                        background-size: 100%;
                        background-repeat: no-repeat;
                        border-radius: 50%;
                        position: relative;
                        .share-icon {
                            width: 0.56rem;
                        }
                        &:first-child {
                            .tips {
                                border-radius: 50%;
                                top: -0.1rem;
                                right: -0.3rem;
                                font-size: 0.2rem;
                                color: #fff;
                                position: absolute;
                                width: 0.6rem;
                                height: 0.4rem;
                                background: red;
                                line-height: 0.4rem;
                                padding: 0 0.05rem;
                                box-sizing: border-box;
                            }
                        }
                    }
                    .share-icon {
                        width: 0.96rem;
                        height: 0.96rem;
                    }
                    .share-text {
                        font-size: 0.24rem;
                        margin-top: 0.16rem;
                        text-align: center;
                    }
                }
            }
        }
        .content-example {
            .example-image {
                margin-top: 0.48rem;
                .image {
                    width: 100%;
                }
            }
        }
    }
    .invite-popup-footer {
        padding-top: 0.16rem;
        background-color: #f5f5f5;
        .footer-cancel {
            height: 1.12rem;
            line-height: 1.12rem;
            font-size: 0.32rem;
            text-align: center;
            background-color: #fff;
        }
    }
    .popupOrnament {
        width: 0.72rem;
        height: 0.08rem;
        border-radius: 0.04rem;
        background: rgba(0, 0, 0, 0.08);
        margin: 0.12rem auto 0.24rem;
    }
    .popupContent {
        width: 100%;
        padding: 0 0.32rem 0.32rem;
        box-sizing: border-box;
        .popupInviteContent {
            box-sizing: border-box;
            background: #fff;
            border-radius: 0.16rem;
            margin-bottom: 0.24rem;
            .promoteSpeechSkills {
                padding: 0.24rem 0.24rem 0.4rem;
                box-sizing: border-box;
                .title {
                    display: flex;
                    color: #999999;
                    font-size: 0.24rem;
                    margin-bottom: 0.16rem;
                    .titleLeft {
                        flex: 1;
                    }
                    .titleRight {
                        width: 44px;
                        position: relative;
                        height: 0.32rem;
                        line-height: 0.32rem;
                        img {
                            position: absolute;
                            top: -0.02rem;
                            right: 0;
                            width: 0.32rem;
                            height: 0.32rem;
                        }
                    }
                }
                .promoteSpeech {
                    width: 100%;
                    position: relative;
                    margin-bottom: 0.4rem;
                    .openText {
                        color: #333;
                        font-size: 0.28rem;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        word-break: break-all;
                        overflow: hidden;
                        i {
                            // display: inline-block;
                            font-style: normal;
                        }
                    }
                    .text {
                        word-wrap: break-word;
                        word-break: break-all;
                        color: #333;
                        font-size: 0.28rem;
                    }
                    .compareText {
                        position: absolute;
                        top: 0;
                        left: 0;
                        word-wrap: break-word;
                        word-break: break-all;
                        color: #333;
                        font-size: 0.28rem;
                        display: none;
                        z-index: -1;
                    }
                    .noShareContent {
                        color: #999999;
                        font-size: 0.28rem;
                    }
                }
            }
            .shareContent {
                padding: 0.32rem 0.24rem;
            }
            .operationTabs {
                display: flex;
                li {
                    list-style: none;
                    width: 33.33%;
                    text-align: center;
                    img {
                        height: 0.96rem;
                        width: 0.96rem;
                        display: block;
                        margin: 0 auto 0.16rem;
                    }
                    p {
                        color: #666666;
                        font-size: 0.24rem;
                        line-height: 0.24rem;
                    }
                }
            }
            .promoteSettings {
                padding: 0.24rem;
                color: #333333;
                font-size: 0.28rem;
                position: relative;
                cursor: pointer;
                .arror-icon {
                    position: absolute;
                    top: 0.28rem;
                    right: 0.16rem;
                    width: 0.32rem;
                    height: 0.32rem;
                }
            }
        }
    }
}
</style>
